import React, { useState, useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import ContactPage from "components/ContactPage";
import Loader from "components/loader";
import ThankYouForAnswering from "components/thankYouForAnswering";
import WelcomeContainer from "containers/Welcome";
import WizardContainer from "containers/Wizard";
import jwt from "jsonwebtoken";
import useAppStore from "stores/appStore";
import { storeLangSettings } from "utils/language";

console.log("App.js: Initializing application...");

const getTheme = ({ primaryColor, secondaryColor }) => {
  console.log("App.js: Setting theme colors", { primaryColor, secondaryColor });
  return createMuiTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
  });
};

const getParams = () => {
  const url = new URL(window.location);
  const params = {
    hash: url.searchParams.get("hash"),
    email: url.searchParams.get("email"),
    surveyId: url.searchParams.get("surveyId"),
  };
  console.log("App.js: Extracted URL parameters:", params);
  return params;
};

const generateAccessToken = (hash) => {
  console.log("App.js: Generating access token for hash:", hash);
  const payload = JSON.stringify({ participantId: hash });
  return jwt.sign(
    { data: payload },
    process.env.REACT_APP_PLATFORM_JWT_SECRET,
    { expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN }
  );
};

const App = () => {
  console.log("App.js: Component rendering...");

  const [isWelcomePage, setWelcomePage] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [userAlreadyAnswered, setUserAlreadyAnswered] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  
  const { hash, email, surveyId } = getParams();
  const { setAccessToken, accessToken: storeToken, setOriginalQuestions, testMode, setTestMode, lang,
    setLang, setTranslatedQuestions } = useAppStore();
  const participantAccessToken = generateAccessToken(hash);
  
  useEffect(() => {
    console.log("App.js: Checking if access token is stored...");
    if (!storeToken) {
      console.log("App.js: Storing new access token.");
      setAccessToken(participantAccessToken);
    }
  }, [participantAccessToken]);

  // Geolocation useEffect
  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response || !response.ok) {
          const userLocale = Intl.DateTimeFormat()
            .resolvedOptions()
            .locale.split("-")[0];
          setLang(userLocale);
          storeLangSettings(userLocale);
          return;
        }
        const data = await response.json();
        const countryCode = data.country_code.toLowerCase();
        const languageMap = {
          us: "en",
          gb: "en",
          au: "en",
          nz: "en",
          br: "pt",
          pt: "pt",
          it: "it",
          es: "es",
          mx: "es",
          ca: "en",
          ar: "es",
          cl: "es",
          co: "es",
          pe: "es",
          ec: "es",
          uy: "es",
          py: "es",
          bo: "es",
          jp: "ja",
          // German-speaking countries
          // de: "de",
          // at: "de",
          // ch: "de",
          // li: "de",
          // lu: "de",
          // be: "de",

          // // French-speaking countries
          // fr: "fr",
          // be: "fr",
          // bf: "fr",
          // bi: "fr",
          // bj: "fr",
          // cd: "fr",
          // cf: "fr",
          // cg: "fr",
          // ci: "fr",
          // cm: "fr",
          // dj: "fr",
          // dz: "fr",
          // ga: "fr",
          // gf: "fr",
          // gn: "fr",
          // gp: "fr",
          // ht: "fr",
          // km: "fr",
          // mg: "fr",
          // ml: "fr",
          // mq: "fr",
          // nc: "fr",
          // ne: "fr",
          // pf: "fr",
          // rw: "fr",
          // sc: "fr",
          // sn: "fr",
          // td: "fr",
          // tg: "fr",
          // tn: "fr",
          // vu: "fr",
          // wf: "fr",
          // // Chinese-speaking countries
          // cn: "zh",
          // hk: "zh",
          // mo: "zh",
          // sg: "zh",
          // tw: "zh",
        };
        const detectedLanguage = languageMap[countryCode] || "en";
        storeLangSettings(detectedLanguage);
        setLang(detectedLanguage);
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        const userLocale = Intl.DateTimeFormat()
          .resolvedOptions()
          .locale.split("-")[0];
        storeLangSettings(userLocale);
        setLang(userLocale);
      }
    };

    fetchGeolocation();
  }, []);

  useEffect(() => {
    if (!surveyId) {
      console.warn("App.js: No surveyId found, skipping data fetch.");
      return;
    }

    console.log("App.js: Fetching survey data for surveyId:", surveyId);

    const fetchSurveyData = async () => {
      let version;
      try {
        console.log("App.js: Fetching API version...");
        const { data } = await axios.get(`${process.env.REACT_APP_FASTIFY_API_URL}/v1/version`);
        version = data.version;
        console.log("App.js: API version retrieved:", version);
      } catch (error) {
        console.error("App.js: Error fetching API version:", error.message);
        return;
      }

      setLoading(true);
      try {
        const specialId = process.env.REACT_APP_TEST_USER_ID;
        let endpoint = `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/surveys/${surveyId}/participant/${hash}`;

        if (hash === specialId) {
          console.log("App.js: Using special ID logic.");
          endpoint = `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/surveys/${surveyId}`;
          setTestMode(true);
        } else {
          setTestMode(false);
        }

        console.log(`App.js: Fetching survey data from: ${endpoint}`);

        const response = await axios.get(endpoint, {
          headers: { Authorization: `Bearer ${participantAccessToken}` }
        });
        console.log("App.js: Survey data received:", response.data);
        const data = response.data;
        console.log("App.js: Survey data received:", data);

        // Check if the survey status indicates that it is already completed.
        // Adjust the property name and status value based on your API response.
        if (data.status === "completed" || data.status === "inactive") {
          console.warn("App.js: Survey is already completed. User already answered.");
          setUserAlreadyAnswered(true);
          setFormData([]); // Optionally clear formData if needed
          return; // Stop further processing of the survey data
        }

        const transformedData = hash === specialId 
          ? {
              questions: data.settings.questions.map((question) => ({
                id: question.id,
                title: question.name,
                name: question.content,
                options: [],
                type: "multiSelect",
              })),
              settings: {
                primaryColor: data.settings.primaryColor,
                secondaryColor: data.settings.secondaryColor,
                logoBase64: data.settings.logoBase64,
              },
              welcomePageString: data.settings.welcomePageHtml,
              welcomePageString2: data.settings.privacyPageHtml,
            }
          : {
              questions: data.survey.settings.questions.map((question) => ({
                id: question.id,
                title: question.name,
                name: question.content,
                options: [],
                type: "multiSelect",
              })),
              settings: {
                primaryColor: data.survey.settings.primaryColor,
                secondaryColor: data.survey.settings.secondaryColor,
                logoBase64: data.survey.settings.logoBase64,
              },
              welcomePageString: data.survey.settings.welcomePageHtml,
              welcomePageString2: data.survey.settings.privacyPageHtml,
            };

        console.log("App.js: Transformed survey data:", transformedData);

        setOriginalQuestions(transformedData.questions);
        setFormData(transformedData);
      } catch (error) {
        console.error("App.js: Error fetching survey data:", error.message);

        if (error?.response?.status === 409) {
          console.warn("App.js: User already answered the survey.");
          setUserAlreadyAnswered(true);
          setFormData([]);
        } else {
          setFormData(null);
        }
      }
    };

    fetchSurveyData();
  }, [surveyId]);

  useEffect(() => {
    if (formData) {
      console.log("App.js: Form data is ready, stopping loader.");
      setLoading(false);
    }
  }, [formData]);

  useEffect(() => {
    if (formData && lang) {
      setTranslatedQuestions(formData.questions, lang).then(() => {
        console.log("App.js setTranslatedQuestions done");
      });
      setLoading(false);
    }
  }, [formData, lang]);

  console.log("App.js: Rendering UI...");
  if (isLoading) {
    console.log("App.js: Showing Loader...");
    return <Loader />;
  }

  if (!formData) {
    console.warn("App.js: No form data available, showing ContactPage.");
    return <ContactPage />;
  }

  if (userAlreadyAnswered) {
    console.warn("App.js: User has already answered, showing ThankYouForAnswering.");
    return <ThankYouForAnswering />;
  }

  if (isWelcomePage) {
    console.log("App.js: Showing Welcome Page.");
    return (
      <ThemeProvider theme={getTheme(formData?.settings)}>
        <WelcomeContainer
          onNextClick={() => {
            console.log("App.js: User clicked Next on Welcome Page.");
            setWelcomePage(false);
          }}
          welcomePageString={formData?.welcomePageString}
          welcomePageString2={formData?.welcomePageString2}
          settings={formData?.settings}
        />
      </ThemeProvider>
    );
  }

  console.log("App.js: Showing WizardContainer.");
  return (
    <ThemeProvider theme={getTheme(formData.settings)}>
      <WizardContainer
        formData={formData}
        email={email}
        settings={formData?.settings}
        onFinish={() => {
          console.log("App.js: Survey finished, reloading page...");
          setReloadPage(!reloadPage);
        }}
        participantAccessToken={participantAccessToken}
      />
    </ThemeProvider>
  );
};

export default App;
